// import store from "@/state/store";

export default [
  // {
  //   path: "/",
  //   name: "default",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/dashboards/default"),
  // },
  {
    path: "/",
    name: "default",
    meta: { authRequired: true },
    component: () => import("./views/applicant/old-records"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/email"),
  },
  {
    path: "/staff_login",
    name: "staff_login",
    component: () => import("./views/account/email_staff"),
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("./views/account/login"),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("./views/account/register"),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  // {
  //   path: "/forgot-password",
  //   name: "Forgot password",
  //   component: () => import("./views/account/forgot-password"),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.removeItem('accessToken');
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          {if (localStorage.getItem('userType') === "applicant") { console.log(localStorage.getItem('userType'), "111---");route.push("/login") } else { console.log(localStorage.getItem('userType'), "222---");route.push("/staff_login?logout") }}  
          // route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  // {
  //   path: "/404",
  //   name: "404",
  //   component: require("./views/utility/404").default,
  // },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },

  // Admin Start Routing

  {
    path: "/admin_dashboard",
    name: "admin dashaboard",
    meta: { authRequired: true },
    component: () => import("./views/admin/admin_dashboard"),
  },

  {
    path: "/staff_users",
    name: "Staff Users",
    meta: { authRequired: true },
    component: () => import("./views/admin/staff_users"),
  },

  {
    path: "/single_applicant/:id", // Route with a parameter named 'id'
    name: "Single Applicant",
    meta: { authRequired: true },
    component: () => import("./views/admin/single_applicant"),
    props: true, // Pass route params as props to the component
  },
  {
    path: "/applicants",
    name: "Applicants",
    meta: { authRequired: true },
    component: () => import("./views/admin/applicants"),
  },
  {
    path: "/admin_notifications",
    name: "Admin Notifications",
    meta: { authRequired: true },
    component: () => import("./views/admin/admin_notifications"),
  },
  {
    path: "/submit",
    name: "Admin Notifications",
    meta: { authRequired: true },
    component: () => import("./views/admin/submitted_under"),
  },
  
  {
    path: "/archived_staff_members",
    name: "Archived Staff Members",
    meta: { authRequired: true },
    component: () => import("./views/admin/archived_staff_member"),
  },
  {
    path: "/single_staff/:id",
    name: "Single Staff",
    meta: { authRequired: true },
    component: () => import("./views/admin/single_staff"),
  },
  {
    path: "/edit_staff/:id",
    name: "Edit Staff",
    meta: { authRequired: true },
    component: () => import("./views/admin/single_staff"),
  },
  // Admin End Routing


  // Applicant Routing Start
  {
    path: "/",
    name: "default",
    meta: { authRequired: true },
    component: () => import("./views/applicant/dashboard"),
  },
  {
    path: "/membership_application",
    name: "membership_application",
    meta: {
          authRequired: true,
          nav: 1,
    },
    component: () => import("./views/applicant/membership_application"),
  },
  {
    path: "/membership_application_edit",
    name: "membership_application_edit",
    meta: {
          authRequired: true,
          nav: 1,
    },
    component: () => import("./views/applicant/membership_application_edit"),
  },
  {
    path: "/view_application/:id", // ":id" represents the dynamic ID parameter
    name: "view_application",
    meta: {
      authRequired: true,
      nav: 1,
    },
    component: () => import("./views/staff/membership_application"),
    props: (route) => ({ id: route.params.app_id }) // Pass app_id as id prop
  },
  {
    path: "/review_application/:id", // ":id" represents the dynamic ID parameter
    name: "review_application",
    meta: {
      authRequired: true,
      nav: 1,
    },
    component: () => import("./views/staff/membership_application"),
    props: (route) => ({ id: route.params.app_id }) // Pass app_id as id prop
  },
  // {
  //   path: "/old_records",
  //   name: "old_records",
  //   component: () => import("./views/applicant/old-records"),
  // },

  {
    path: "/view_log",
    name: "View Log",
    meta: { authRequired: true },
    component: () => import("./views/applicant/view-log"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    meta: { authRequired: true },
    component: () => import("./views/applicant/notifications"),
  },
  // Applicant Routing End



  // Staff Routing Start
  {
    path: "/application_list",
    name: "application list",
    meta: { authRequired: true },
    component: () => import("./views/staff/application_list.vue"),
  },
  {
    path: "/staff_view_log",
    name: "Staff View Log",
    meta: { authRequired: true },
    component: () => import("./views/staff/staff_view_log"),
  },
  {
    path: "/staff_dashboard",
    name: "Dashboard",
    meta: { authRequired: true },
    component: () => import("./views/staff/staff_dashboard"),
  },
  {
    path: "/approved_application",
    name: "Approved Application",
    meta: { authRequired: true },
    component: () => import("./views/staff/approved_application"),
  },
  {
    path: "/rejected_application",
    name: "Rejected Application",
    meta: { authRequired: true },
    component: () => import("./views/staff/rejected_application"),
  },
  // {
  //   path: "/pdf_data",
  //   name: "PDF Application",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/pdfData"),
  // },
  {
    path: "/pdf_data/:id", // ":id" represents the dynamic ID parameter
    name: "PDF Application",
    meta: {
      authRequired: true,
      nav: 1,
    },
    component: () => import("./views/staff/pdfData"),
    props: (route) => ({ id: route.params.app_id }) // Pass app_id as id prop
  },
  {
    path: "/incomplete_application",
    name: "Incomplete Application",
    meta: { authRequired: true },
    component: () => import("./views/staff/incomplete_application"),
  },
  {
    path: "/staff_notification",
    name: "Staff Notification",
    meta: { authRequired: true },
    component: () => import("./views/staff/staff_notification"),
  },
  {
    path: "/my_profile",
    name: "My Profile",
    meta: { authRequired: true },
    component: () => import("./views/staff/staff_profile"),
  },
  // Staff Routing End
  {
    path: "/auth/login-1",
    name: "Login sample",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/login-sample"),
  },
// 
];
