import { createStore } from 'vuex'

import modules from './modules'

const store = createStore({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  state() {
    return {
      msalConfig: {
        auth: {
          clientId    : '7e6770d4-c2fc-4191-aeee-e474c1739164',//client id
          authority   : 'https://login.microsoftonline.com/7733bbed-d749-4ba2-83ea-5a2595e37589',//for users added only
          redirectUri : "https://joinus.ecunewexperience.com/staff_login",
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      accessToken: ''
    };
  },
  mutations :{
    setAccessToken(state, token){
      state.accessToken = token;
      // extravar['mtoken'].value = token;
      // console.log('token updated________________________________', token)
    }
  }
})

export default store

